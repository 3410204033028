<template lang="pug">
  ValidationObserver(ref="form" slim)
    form(@submit.prevent="onSubmit" class="form-document-fields")
      h3(class="title-20px mb-2") {{ $t('Quick fill') }}
      div(class="text-14px mb-8") {{ $t('Fill documents dynamic fields in simplified form') }}
      div(class="form-document-fields__fields-wrapper")
        ValidationProvider(
          v-for="field in formattedFields"
          :name="field.hint"
          class="d-block mb-8"
          v-slot="{ errors }"
        )
          component(
            v-model="form[field.documentFieldId]"
            :is="fieldComponent[field.type]"
            filled
            :label="setFieldLabel(field)"
            v-bind="setFieldTypeRelatedProps(field)"
            class="form-document-fields__input"
            :error-messages="errors"
            data-lpignore="true"
          )
            template(v-if="handleShowAppendIcon(field)" v-slot:append)
              e-svg-icon(:name="setFieldIcon(field.type)")
      div(class="form-document-fields__actions")
        v-btn(
          height="48"
          @click="closeModal"
        ) {{ $t('Cancel') }}
        v-btn(
          class="main-button"
          type="submit"
        ) {{ $t('Apply') }}
</template>

<script>
import { VCheckbox, VSelect, VTextField } from 'vuetify/lib'
import { mapGetters } from 'vuex'
import EInputDatetime from '~/components/elements/inputs/e-input-datetime'
import EInputSignature from '~/components/elements/inputs/e-input-signature'
import TemplateField from '~/modules/templates/models/TemplateField'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

const DATEPICKER_MENU_OFFSET = 320
const SIGNATURE_MENU_OFFSET = 490

export default {
  name: 'FormDocumentFields',
  components: {
    VTextField,
    VSelect,
    VCheckbox,
    EInputDatetime,
    EInputSignature,
    ESvgIcon
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    closeModal: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters('templatefields', {
      filledFields: 'filledFields',
      templateFields: 'fields'
    }),
    filteredFields () {
      return this._.filter(this.fields, field => field.type !== TemplateField.TYPES.formula)
    },
    formattedFields () {
      const clonedFilteredFields = this._.cloneDeep(this.filteredFields)
      const sortedFields = this._.sortBy(clonedFilteredFields, [f => f.positionY])
      return this._.map(sortedFields, field => (
        {
          ...field,
          documentFieldId: field.id,
          documentFieldValue: field.value,
          documentFieldPreValue: field.preValue
        })
      )
    },
    fieldComponent () {
      return {
        text: 'v-text-field',
        date: 'e-input-datetime',
        checkbox: 'v-checkbox',
        select: 'v-select',
        numeric: 'v-text-field',
        formula: 'v-text-field',
        signature: 'e-input-signature'
      }
    },
    fieldIconsMap () {
      return {
        text: 'text-symbol-gray',
        formula: 'formula-gray',
        select: 'arrow-down-dark-gray'
      }
    }
  },
  created () {
    for (const field of this.fields) {
      this.$set(this.form, field.id, field.value)
    }
    this.fillForm()
  },
  methods: {
    fillForm () {
      for (const filledField of this.filledFields) {
        const field = this._.find(this.templateFields, field => field.documentFieldId === filledField.id)
        this.form[field.id] = filledField.value
      }
    },
    handleShowAppendIcon (field) {
      return field.type !== TemplateField.TYPES.select
    },
    setFieldTypeRelatedProps (field) {
      const props = {}
      props.hideDetails = 'auto'

      if (field.type === TemplateField.TYPES.select) {
        props.items = field.options
        props.menuProps = {
          bottom: true,
          offsetY: true
        }
      } else if (field.type === TemplateField.TYPES.numeric) {
        props.type = 'number'
      } else if (field.type === TemplateField.TYPES.date) {
        props.menuLeftOffset = DATEPICKER_MENU_OFFSET
        props.clearable = true
      } else if (field.type === TemplateField.TYPES.signature) {
        props.menuLeftOffset = SIGNATURE_MENU_OFFSET
      } else if (field.type === TemplateField.TYPES.checkbox) {
        props.trueValue = 'checked'
      }

      return props
    },
    setFieldLabel (field) {
      if (field.type === TemplateField.TYPES.checkbox) {
        return this.$t('Checkbox')
      }
      return field.hint
    },
    setFieldIcon (type) {
      return this.fieldIconsMap[type] ?? null
    },
    onSubmit () {
      Object.entries(this.form).forEach(([fieldId, value]) => {
        this.$store.commit('templatefields/SET_FIELD_QUICK_VALUE_BY_FIELD_ID', { id: fieldId, value })
      })
      this.closeModal()
    }
  }
}
</script>

<style scoped lang="scss">
.form-document-fields {
  display: flex;
  flex-direction: column;

  position: relative;

  padding: 20px;

  //&__fields-wrapper {
  //  padding-bottom: 80px;
  //}

  &__input {

    &::v-deep {
      input {
        /* hiding arrows for number field */
        -moz-appearance: textfield;
        appearance: none;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }

    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    padding: 20px 0 0 0;

    border-radius: 0 0 12px 12px;
    border-top: 1px solid #F1F1F1;
    background: #FFF;
  }
}
</style>

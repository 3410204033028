<template lang="pug">
  v-dialog(
    v-bind="$attrs"
    v-on="$listeners"
    :value="isOpened"
    :max-width="maxWidth"
    content-class="m-dialog-document-fields-form"
    scrollable
    persistent
  )
    v-btn(
      class="m-dialog-document-fields-form__close-btn"
      color="rgba(22, 27, 37, 0.5)"
      v-bind="$attrs"
      @click="closeDialog"
      x-small
      fab
      text
    )
      v-icon(size="24") mdi-close
    form-document-fields(
      v-if="isOpened"
      :fields="fields"
      :closeModal="closeDialog"
    )
</template>

<script>
import Dialog from '~/models/system/Dialog'
import FormDocumentFields from '~/modules/documents/components/forms/form-document-fields'

export default {
  name: 'MDialogDocumentFieldsForm',
  components: {
    FormDocumentFields
  },
  props: {
    type: {
      type: String,
      default: 'documentFieldsFormDialog'
    }
  },
  computed: {
    dialog () {
      return Dialog.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.dialog, 'isOpened', false)
    },
    maxWidth () {
      return 390
    },
    params () {
      return this._.get(this.dialog, 'params')
    },
    fields () {
      return this._.get(this.params, 'fields', [])
    }
  },
  created () {
    if (!this.dialog) {
      Dialog.insert({
        data: {
          type: this.type
        }
      })
    }
  },
  methods: {
    async closeDialog (data) {
      await this.dialog.close(data, true)
    }
  }
}
</script>

<style scoped lang="scss">
.v-dialog {
  &__content {
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.m-dialog-document-fields-form {
  &__close-btn {
    position: absolute !important;
    top: 40px;
    right: 40px;
    z-index: 2;
  }
}
</style>

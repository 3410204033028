import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class Gallery extends OrmModel {
  static entity = 'gallery'
  static paginated = true

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(null),
      thumbnailUrl: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)

      configActions.cloneTemplate = function (id) {
        return this.post(Model.$routes.gallery.cloneTemplate(id), {}, { save: false })
      }

      return configActions
    }
  }
}

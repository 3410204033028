import Dialog from '~/models/system/Dialog'

const documentFieldsFormDialog = {
  computed: {
    documentFieldsFormDialog () {
      return Dialog.query().where('type', 'documentFieldsFormDialog').first()
    }
  }
}
export default documentFieldsFormDialog

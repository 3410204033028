import { render, staticRenderFns } from "./m-dialog-document-fields-form.vue?vue&type=template&id=31858bd8&scoped=true&lang=pug&"
import script from "./m-dialog-document-fields-form.vue?vue&type=script&lang=js&"
export * from "./m-dialog-document-fields-form.vue?vue&type=script&lang=js&"
import style0 from "./m-dialog-document-fields-form.vue?vue&type=style&index=0&id=31858bd8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31858bd8",
  null
  
)

export default component.exports
import introductionGuideline from '~/modules/guideline/mixins/introductionGuideline'

const templateOnboarding = {
  mixins: [introductionGuideline],
  methods: {
    checkShowTemplatesOnboarding () {
      if (!this._.has(this.$localStorageClientData, 'showTemplatesOnboardingTour')) {
        this.$setLocalStorageClientData({
          showTemplatesOnboardingTour: true
        })
        this.showTemplatesGuideline()
      } else if (this._.get(this.$localStorageClientData, 'showTemplatesOnboardingTour', false)) {
        this.showTemplatesGuideline()
      }
    },
    showTemplatesGuideline () {
      this.introductionGuideline.show({
        steps: [
          {
            imagePath: require('~/assets/images/templates-introduction/templates-introduction-1.png'),
            imageClass: 'd-flex align-end justify-center',
            title: this.$t('Hi {name}', {
              name: this.$User.name || this.$User.email
            }),
            text: this.$t('Templates gives opportunity to create interactive documents, send them, fill and sign'),
            nextButtonText: this.$t('How it works')
          },
          {
            imagePath: require('~/assets/images/templates-introduction/templates-introduction-2.png'),
            imageClass: 'd-flex align-end',
            title: this.$t('Upload document'),
            text: this.$t('This could be a contract, application, or any other document you want to automate')
          },
          {
            imagePath: require('~/assets/images/templates-introduction/templates-introduction-3.png'),
            imageClass: 'd-flex justify-center align-start',
            title: this.$t('Setup dynamic fields'),
            text: this.$t('Allow recipients to fill out the document exactly as needed')
          },
          {
            imagePath: require('~/assets/images/templates-introduction/templates-introduction-4.png'),
            imageClass: 'd-flex align-end',
            title: this.$t('Send template'),
            text: this.$t('Share a public template via link or QR code. The template is always saved in your account'),
            nextButtonText: this.$t('Try')
          }
        ],
        onFinish: this.onFinish,
        onClose: this.hideGuidelineTour
      })
    },
    hideGuidelineTour () {
      this.$setLocalStorageClientData({
        showTemplatesOnboardingTour: false
      })
    },
    onFinish () {
      this.hideGuidelineTour()
      this.$router.push({ name: 'templates' })
    }
  }
}

export default templateOnboarding

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VNavigationDrawer,{staticClass:"drawer",attrs:{"color":_vm.$vuetify.theme.currentTheme.drawer,"app":"","id":"app-sidebar","permanent":"","clipped":"","width":"200","fixed":"","floating":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center mb-10"},[_c('nuxt-link',{attrs:{"to":"/documents","exact":""}},[_c(VImg,{attrs:{"src":require('~/assets/images/logo/logo-black.svg'),"max-width":"152"}})],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.$route.name === 'templates')?_c('e-button-template-upload'):_c('e-button-document-upload'),_c(VList,[_c(VListItemGroup,{model:{value:(_vm.activeLink),callback:function ($$v) {_vm.activeLink=$$v},expression:"activeLink"}},[_vm._l((_vm.items),function(link,index){return [_c('nuxt-link',{staticClass:"d-flex align-center link",attrs:{"to":link.href,"exact":"","active-class":"link--active"}},[(_vm.isVisible(link))?_c(VListItem,{key:index,staticClass:"list__item"},[_c(VListItemIcon,{staticClass:"mr-0"},[_c('e-svg-icon',{attrs:{"name":_vm.isRouteActive(link.href) ? link.activeIcon : link.icon}})],1),_c(VListItemTitle,{staticClass:"link__text pl-3"},[_vm._v(_vm._s(_vm.$t(link.text)))]),(link.isNew)?_c('span',{staticClass:"link__new"},[_vm._v("New")]):_vm._e()],1):_vm._e()],1)]}),_c(VBtn,{staticClass:"tags-btn",on:{"click":_vm.createTag}},[_c(VListItemTitle,{staticClass:"text-left fw-500"},[_vm._v(_vm._s(_vm.$t('Tags')))]),_c(VListItemAction,[_c('e-svg-icon',[_vm._v("plus-black")])],1)],1),_vm._l((_vm.tags),function(tag,index){return [_c(VHover,{key:tag.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VListItem,{staticClass:"list__item",on:{"click":function($event){return _vm.onTagClick(tag.id)}}},[_c(VListItemIcon,{staticClass:"ma-0 d-flex justify-center align-center align-self-center h-100"},[_c('div',{staticClass:"tag-circle",style:({ backgroundColor: tag.color })})]),_c(VListItemTitle,{staticClass:"link__text pl-3"},[_vm._v(_vm._s(tag.name))]),(hover || _vm.onHoverTags[tag.id])?_c(VListItemAction,{staticClass:"ma-0 pr-3"},[_c('e-tags-menu-actions',{attrs:{"value":_vm.onHoverTags[tag.id],"visibility-tags":_vm.visibilityTags,"tag":tag},on:{"input":function($event){_vm.onHoverTags[tag.id] = !_vm.onHoverTags[tag.id]},"onSettings":_vm.onTagSettingsClick,"updateVisibility":_vm.updateTagVisibility}})],1):_vm._e()],1)]}}],null,true)})]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
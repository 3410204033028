<template lang="pug">
  div(class="form-document-signatures")
    div(class="form-document-signatures__section")
      h5(class="form-document-signatures__title") {{ $t('Signs') }}
      template(v-if="!signatures.length")
        p {{ $t('None of the participants have signed the document yet') }}
      template(v-else)
        div(
          v-for="signature in signatures"
          :key="signature.id"
          class="form-document-signatures__item"
        )
          div
            e-svg-icon(size="xxlg") key-bg
          div
            div(class="form-document-signatures__item-name") {{ signature.name }}
            div(class="form-document-signatures__item-bottom")
              div(class="form-document-signatures__item-code") {{ signature.code }}
              div(class="form-document-signatures__item-date") {{ formatTime(signature.signedAt) }}
    div(v-if="canSend" class="form-document-signatures__section")
      h5(class="form-document-signatures__title") {{ $t('Access to document') }}
      div(class="form-document-signatures__item")
        div(class="form-document-signatures__item-icon")
          v-img(:src="require('~/assets/images/person.png')" max-width="16")
        div
          div(class="form-document-signatures__item-name") {{ $t('You') }}
          div(class="form-document-signatures__item-code") {{ $User.email }}
      div(
        v-for="participant in participants"
        :key="participant.id"
        class="form-document-signatures__item"
      )
        div(class="form-document-signatures__item-icon")
          v-img(:src="require('~/assets/images/person.png')" max-width="16")
        div
          div(class="form-document-signatures__item-name") {{ _.get(participant, 'user.name') }}
          div(class="form-document-signatures__item-code") {{ _.get(participant, 'user.email') }}
      v-btn(
        class="secondary-button"
        @click="showShare(document)"
      ) {{ $t('Share') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import dateTime from '~/mixins/datetime/dateTime'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import documentActions from '~/modules/documents/mixins/documentActions'

export default {
  name: 'FormDocumentSignatures',
  components: {
    EProgressCircular,
    ESvgIcon
  },
  mixins: [documentActions, dateTime],
  props: {
    model: {
      type: Function,
      default: () => {}
    },
    document: {
      type: Object,
      default: () => ({})
    },
    signatures: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      participantsLoading: false,
      participants: []
    }
  },
  computed: {
    canSend () {
      return this._.get(this.document, 'canSend', false)
    }
  },
  async created () {
    await this.getParticipants()
  },
  methods: {
    async getParticipants () {
      try {
        this.participantsLoading = true
        this.participants = this._.get(await this.model.api().getParticipants(this.document.id), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.participantsLoading = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-document-signatures {
  &__section {
    border-radius: 12px;
    background: #fff;
    padding: 16px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__title {
    color: #000;
    font-variant-numeric: slashed-zero;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-icon {
      width: 40px;
      height: 40px;
      border-radius: 12px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: $background-secondary;
    }

    &-name {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
    &-code {
      color: rgba(0, 0, 0, 0.50);
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
    &-date {
      color: #000;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
  }
}
</style>

<template lang="pug">
  v-snackbar(:value="isOpened" :timeout="timeout" rounded="lg" content-class="d-flex align-center")
    e-svg-icon(v-if="icon" class="mr-4") {{ icon }}
    | {{ text }}
    v-btn(
      v-if="onButtonClick"
      :text="buttonTextVariant"
      :color="$vuetify.theme.themes.light.error"
      :class="buttonClass"
      @click="onButtonClick"
    ) {{ $t(buttonText) }}
</template>

<script>
import Snackbar from '~/modules/snackbar/models/Snackbar'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'InformationSnackbar',
  components: { ESvgIcon },
  props: {
    type: {
      type: String,
      default: 'information'
    }
  },
  computed: {
    snackbar () {
      return Snackbar.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.snackbar, 'isOpen', false)
    },
    params () {
      return this._.get(this.snackbar, 'params', {})
    },
    text () {
      return this._.get(this.params, 'text', '')
    },
    timeout () {
      return this._.get(this.params, 'timeout', 3000)
    },
    onButtonClick () {
      return this._.get(this.params, 'onButtonClick', null)
    },
    buttonText () {
      return this._.get(this.params, 'buttonText', '')
    },
    buttonClass () {
      return this._.get(this.params, 'buttonClass', '')
    },
    buttonTextVariant () {
      return this._.get(this.params, 'buttonTextVariant', false)
    },
    icon () {
      return this._.get(this.params, 'icon', null)
    }
  },
  created () {
    if (!this.snackbar) {
      Snackbar.insert({
        data: {
          type: this.type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>

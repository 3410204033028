import { Model } from '@vuex-orm/core'
import OrmModel from '~/models/abstracts/base/OrmModel'

export default class GalleryCategory extends OrmModel {
  static entity = 'gallerycategory'
  static paginated = true

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  static apiConfig = {
    get actions () {
      const configActions = Object.assign({}, Model.apiConfig.actions)
      return configActions
    }
  }
}

<template lang="pug">
  e-template-uploader(@close="closeModal")
    div(class="templates-card cursor-pointer w-100")
      div(class="templates-card__thumbnail d-flex align-center justify-center")
        e-svg-icon plus-black
      div(class="templates-card__content")
        div(class="text-14px sm-line-height dark text-center") {{ $t('New template') }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import ETemplateUploader from '~/modules/templates/components/elements/e-template-uploader'

export default {
  name: 'BlockTemplatesCardUpload',
  components: {
    ESvgIcon,
    ETemplateUploader
  },
  props: {
    closeModal: {
      type: Function,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
</style>

<template lang="pug">
  v-btn(class="main-button w-100 mb-6" @click="openUploadDialog") {{ $t('Create') }}
</template>

<script>
import contentDialog from '~/mixins/dialogs/contentDialog'

export default {
  name: 'EButtonTemplateUpload',
  mixins: [contentDialog],
  methods: {
    openUploadDialog () {
      this.contentDialog.open({
        component: 'm-template-upload',
        width: '900px'
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>

import Guideline from '~/modules/guideline/models/Guideline'

const introductionGuideline = {
  computed: {
    introductionGuideline () {
      return Guideline.query().where('type', 'introduction').first()
    }
  }
}
export default introductionGuideline

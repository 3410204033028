<template lang="pug">
  button(
    class="templates-card"
    @click="toggleCard"
  )
    div(class="templates-card__thumbnail" :class="{ 'selected': isSelected }")
      v-checkbox(
        v-if="isSelected"
        :input-value="true"
        color="#8F257D"
        background-color="#fff"
        readonly
        class="templates-card__checkbox"
        hide-details
        :ripple="false"
      )
      v-img(
        v-if="item.thumbnailUrl"
        :src="item.thumbnailUrl"
        :max-width="128"
        max-height="100%"
        class="templates-card__image"
      )
    div(class="templates-card__content")
      div(class="text-14px sm-line-height dark templates-card__text" :title="title") {{ title }}
</template>

<script>

export default {
  name: 'BlockTemplatesCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    selectedId: {
      type: String,
      default: null
    }
  },
  computed: {
    title () {
      return this._.get(this.item, 'title', '')
    },
    isSelected () {
      return this.item.id === this.selectedId
    }
  },
  methods: {
    toggleCard () {
      if (this.isSelected) {
        this.$emit('select', null)
      } else {
        this.$emit('select', this.item.id)
      }
    }
  }
}
</script>

<style lang="scss">
.templates-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 210px;
  border-radius: 4px;

  &__thumbnail {
    width: 100%;
    max-height: 160px;
    height: 100%;

    position: relative;

    border-radius: 4px;
    border: 1px solid $border-secondary;
    background: $background-primary;

    &.selected {
      border-color: $primary;
    }
  }

  &__checkbox {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;

    margin-top: 0 !important;
    padding-top: 0 !important;

    .v-input--selection-controls__input {
      margin-right: 0 !important;
    }
  }

  &__image {
    border-radius: 4px;
  }

  &__content {
    width: 100%;
    height: 50px;
  }

  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

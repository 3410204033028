<template lang="pug">
  v-col(cols="12" class="py-0 m-group-tabs")
    v-tabs(v-model="tab" height="40px" grow)
      v-tabs-slider(:color="invalidTabs.includes(tab)? 'error': 'primary'")
      v-tab(v-for="(item, key) in items" color="red" :class="invalidTabs.includes(key) ? 'error--text' : ''" :key="item.name")
        | {{ $t(item.text) }}

    v-tabs-items(v-model="tab")
      v-tab-item(v-for="item in items" :key="item.name")
        v-card(flat)
          v-card-text(class="px-0 py-7")
            v-row
              slot(:fields="tabFields(item)" :tab="item")
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    tab: null
  }),
  computed: {
    invalidTabs () {
      const observer = this._.get(this.config, `ctx.dialogRefs.${this._.get(this.config, 'ctx.model', '')}`, null)
      const invalidTabs = []
      if (observer) {
        const invalidFields = []
        this._.forOwn(observer.fields, (value, key) => {
          if (value.invalid && value.validated) {
            invalidFields.push(key)
          }
        })
        this.items.forEach((value, key) => {
          if (this._.intersection(value.fields, invalidFields).length) {
            invalidTabs.push(key)
          }
        })
      }
      return invalidTabs
    }
  },
  watch: {
    invalidTabs (to, prev) {
      if (to.length && to[0] !== prev[0]) {
        this.setTab(to[0])
      }
    }
  },
  created () {
    const def = this.items.findIndex(item => (!!item.active))
    this.tab = def === -1 ? 0 : def
  },
  methods: {
    tabFields (tab) {
      return this._.intersectionWith(this.fields, tab.fields, (arrVal, othVal) => arrVal.model === othVal)
    },
    setTab (tab) {
      this.tab = tab
    }
  }
}
</script>

<style lang="scss">
  .m-group-tabs {
    .v-tabs {
      .v-tab {
        text-transform: none !important;
        font-size: 1.125rem;
        letter-spacing: normal;
        border-bottom: 1px solid $dark-gray;
      }
    }
  }
</style>
